@tailwind base;
@tailwind components;
@tailwind utilities;

/* SLICK */
.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block
}

.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0
}

.slick-list:focus {
  outline: 0
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.slick-track {
  top: 0;
  left: 0
}

.slick-track:after,
.slick-track:before {
  display: table;
  content: ''
}

.slick-track:after {
  clear: both
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px
}

[dir=rtl] .slick-slide {
  float: right
}

.slick-slide img {
  display: block
}

.slick-slide.slick-loading img {
  display: none
}

.slick-slide.dragging img {
  pointer-events: none
}

.slick-initialized .slick-slide {
  display: block
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent
}

.slick-arrow.slick-hidden {
  display: none
}

/*# sourceMappingURL=slick.min.css.map */
.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0
}

.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0
}

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: .25
}

.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #fff
}

.slick-prev {
  left: -25px
}

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto
}

.slick-prev:before {
  content: '←'
}

.slick-next:before,
[dir=rtl] .slick-prev:before {
  content: '→'
}

.slick-next {
  right: -25px
}

[dir=rtl] .slick-next {
  right: auto;
  left: -25px
}

[dir=rtl] .slick-next:before {
  content: '←'
}

.slick-dotted.slick-slider {
  margin-bottom: 30px
}

.slick-dots {
  bottom: -25px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0
}

.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: #000
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #000
}

/*# sourceMappingURL=slick-theme.min.css.map */
@font-face {
  font-family: "CookieRun";
  src: local("CookieRun"), url("./fonts/CookieRun.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "CookieRun";
  src: local("CookieRun"), url("./fonts/CookieRun-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "CookieRun1";
  src: local("CookieRun1"), url("./fonts/CookieRun-Black.ttf") format("truetype");
  font-weight: bolder;
  font-display: swap;
}

.text-shadow {
  text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.26);
}

.react-button {
  transition-duration: 0.3s;
}

.react-button:hover {
  filter: brightness(1.5);
}

.react-button:active {
  filter: brightness(0.8);
}

.react-mongen {
  transition-duration: 0.3s;
  filter: grayscale(100);
}

.react-mongen:hover {
  filter: grayscale(0);
}

.react-text {
  color: rgba(255, 255, 255, 0.6);
  transition-duration: 0.3s;
}

.react-text:hover {
  color: white;
}

.react-text:active {
  color: rgba(255, 255, 255, 0.2);
}

.react-tab {
  opacity: 0.38;
  transition-duration: 0.3s;
}

.react-tab:hover {
  opacity: 1;
}

.react-tab:active {
  opacity: 0.1;
}

.react-social {
  filter: grayscale(0.5);
  transition-duration: 0.2s;
}

.react-social:hover {
  filter: grayscale(0);
  /* transform:translateX(-10px); */
  z-index: 1000;
  transform: scale(1.2) translateX(-5px);
}



body,
html {
  font-family: "CookieRun";
}

.expand {
  /*background-size: cover;*/
  background-repeat: no-repeat;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s linear;
}

.expand.expanded .ModeTitle {
  animation: fade-out 0.8s ease-in-out;
  animation-iteration-count: 1;
  transition-duration: 0.6s;
  opacity: 0;
  bottom: 100px;
}

.expand .ModeTitle {
  animation: fade-in 0.8s ease-in-out;
  animation-iteration-count: 1;
  transition-duration: 0.6s;
  opacity: 1;
}

.expand.expanded .ModeTitle1 {
  animation: fade-in 0.6s ease-in-out;
  animation-iteration-count: 1;
  transition-duration: 0.6s;
  opacity: 1;
  bottom: 24px;
}

.expand .ModeTitle1 {
  animation: fade-out 0.6s ease-in-out;
  animation-iteration-count: 1;
  transition-duration: 0.6s;
  opacity: 0;
}

.ModeTitle1 {
  opacity: 0;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes mongen-appear-animation {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes smoke-appear-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes mongen-shake-animation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes story-appear-animation {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.story-appear-anim {
  animation: story-appear-animation 1.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

.anim-delay-1 {
  animation-delay: 0.25s;
}

.anim-delay-2 {
  animation-delay: 0.5s;
}

.anim-delay-3 {
  animation-delay: 0.75s;
}

.anim-delay-4 {
  animation-delay: 1s;
}

.anim-delay-5 {
  animation-delay: 1.25s;
}

.header-mongen-anim {
  animation: mongen-shake-animation 2s ease-in-out;
  animation-iteration-count: infinite;
}

.mongen-appear {
  animation: mongen-appear-animation 0.6s ease-in-out;
  animation-iteration-count: 1;
  transition-duration: 0.6s;
}

.smoke-appear {
  animation: smoke-appear-animation 1s ease-in-out;
  animation-iteration-count: 1;
}

.dropdown {
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100vw;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdownLand {
  position: relative;
  display: inline-block;
}

.dropdownLand-content {
  /* display: none; */
  position: absolute;
  background-color: rgba(20, 20, 20, 0.85);
  width: 100%;
  overflow: auto;
}

.dropdownLand-content a {
  color: black;
  /* padding: 12px 16px; */
  /* text-decoration: none; */
  display: block;
}

.dropdownLand a:hover {
  background-color: #ddd;
}

.showLand {
  display: block;
}

.expand-mobile {
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s linear;
}

.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  visibility: hidden;
  /* width: 320px; */
  /* background-color: #555; */
  /* color: #fff; */
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 69%;
  left: 117%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

/* .tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 100%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
} */

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.textGradient {
  background: #ffef9b;
  background: -webkit-linear-gradient(to bottom, #ffef9b 20%, #ffd78a 40%, #ffbe40 60%, #ffeecc 80%, #ffdfb9 100%);
  background: -moz-linear-gradient(to bottom, #ffef9b 20%, #ffd78a 40%, #ffbe40 60%, #ffeecc 80%, #ffdfb9 100%);
  background: linear-gradient(to bottom, #ffef9b 20%, #ffd78a 40%, #ffbe40 60%, #ffeecc 80%, #ffdfb9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.spine-player {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: 0 0;
  position: relative;
}

.spine-player * {
  box-sizing: border-box;
  font-family: "PT Sans", Arial, "Helvetica Neue", Helvetica, Tahoma, sans-serif;
  color: #ddd;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.spine-player canvas {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.spine-player #spine-player-button-logo {
  display: none;
}

@media screen and (max-width: 768px) {
  .slick-next {
    z-index: 100;
    right: -10px;
    transform: scale(2);
  }

  .slick-prev {
    z-index: 100;
    left: -10px;
    transform: scale(2);
  }

  .slick-dots {
    bottom: 15px;
  }

  .slick-dots li button:before {
    transform: scale(2);
  }
}

@media screen and (min-width: 769px) {
  .slick-next {
    z-index: 100;
    right: 40px;
    transform: scale(3);
  }

  .slick-prev {
    z-index: 100;
    left: 40px;
    transform: scale(3);
  }

  .slick-dots {
    bottom: 15px;
  }

  .slick-dots li button:before {
    transform: scale(2);
  }
}

@media screen and (min-width: 1024px) {
  .slick-next {
    z-index: 100;
    right: 60px;
    transform: scale(3);
  }

  .slick-prev {
    z-index: 100;
    left: 60px;
    transform: scale(3);
  }

  .slick-dots {
    bottom: 65px;
  }

  .slick-dots li button:before {
    transform: scale(3);
  }
}

.hover-container .show-on-hover {
  display: none;
}

.hover-container:hover>.show-on-hover {
  display: block;
}

.hover-container:hover>.text-white-on-hover {
  color: white !important;
}

.roadmap-nav {
  border-radius: 1rem;
  background: rgba(237, 230, 197, 1);
  display: flex !important;
  position: absolute;
  top: -10px;
  width: 100%;
  padding: 6px 15px;
  justify-content: center;
}

.roadmap-nav li {
  display: inline;
  margin: 0px 10px;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  color: rgba(82, 68, 51, 1);
  opacity: 0.3;
}

.roadmap-nav li.slick-active {
  opacity: 1;
}

.slick-arrow {
  display: none !important;
}

.spine-player-controls {
  display: none;
}

.nooutline,
.nooutline:active,
.nooutline:focus {
  box-shadow: none;
  outline: 0;
  border: none;
}

/* width */
.timeline::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.timeline::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.timeline::-webkit-scrollbar-thumb {
  background: #c2c2c2;
}

/* Handle on hover */
.timeline::-webkit-scrollbar-thumb:hover {
  background: #555;
}